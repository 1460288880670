/* index.css */

/* Modern CSS Reset */
:root {
  --primary: #4361ee; /* Vibrant blue */
  --secondary: #3a0ca3; /* Deep purple */
  --accent: #f72585; /* Pink accent */
  --light: #f8f9fa;
  --dark: #212529;
  --success: #4cc9f0;
  --warning: #f8961e;
  --danger: #ef233c;
  --gray: #adb5bd;
  --white: #ffffff;
  --card-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

/* Improved body styles */
body {
  background-color: var(--light);
  color: var(--dark);
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    sans-serif;
  line-height: 1.6;
  min-height: 100vh;
  text-rendering: optimizeSpeed;
}

/* Fluid container with better breakpoints */
.container {
  width: 100%;
  padding: 0 1rem;
  margin: 0 auto;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
    padding: 0;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1200px;
  }
}

/* Typography scale */
h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--dark);
  margin-bottom: 1rem;
  font-weight: 700;
  line-height: 1.2;
}

h1 {
  font-size: 2.5rem;
}
h2 {
  font-size: 2rem;
}
h3 {
  font-size: 1.75rem;
}
h4 {
  font-size: 1.5rem;
}
h5 {
  font-size: 1.25rem;
}
h6 {
  font-size: 1rem;
}

@media (max-width: 768px) {
  h1 {
    font-size: 2rem;
  }
  h2 {
    font-size: 1.75rem;
  }
  h3 {
    font-size: 1.5rem;
  }
}

/* Enhanced buttons */
button {
  background-color: var(--primary);
  color: var(--white);
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 0.375rem;
  cursor: pointer;
  font-weight: 600;
  font-size: 1rem;
  transition: all 0.2s ease;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

button:hover {
  background-color: var(--secondary);
  transform: translateY(-1px);
  box-shadow: var(--card-shadow);
}

button:active {
  transform: translateY(0);
}

button:focus {
  outline: 2px solid var(--accent);
  outline-offset: 2px;
}

/* Button variants */
.btn-secondary {
  background-color: var(--secondary);
}

.btn-accent {
  background-color: var(--accent);
}

.btn-outline {
  background-color: transparent;
  border: 2px solid var(--primary);
  color: var(--primary);
}

/* Improved form controls */
input[type="text"],
input[type="email"],
input[type="password"],
textarea,
select {
  width: 100%;
  padding: 0.75rem;
  margin-bottom: 1rem;
  border-radius: 0.375rem;
  border: 1px solid var(--gray);
  background-color: var(--white);
  font-size: 1rem;
  color: var(--dark);
  transition: border-color 0.2s ease;
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="password"]:focus,
textarea:focus,
select:focus {
  border-color: var(--primary);
  outline: none;
  box-shadow: 0 0 0 3px rgba(67, 97, 238, 0.2);
}

/* Enhanced table styling */
table {
  width: 100%;
  border-collapse: collapse;
  margin: 1.5rem 0;
  font-size: 0.9rem;
  overflow-x: auto;
  display: block;
}

@media (min-width: 768px) {
  table {
    display: table;
  }
}

th,
td {
  padding: 0.75rem 1rem;
  text-align: left;
  border-bottom: 1px solid #e2e8f0;
}

th {
  background-color: var(--secondary);
  color: var(--white);
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  font-size: 0.75rem;
}

td {
  background-color: var(--white);
}

tr:hover td {
  background-color: #f8fafc;
}

/* Modern navbar */
header {
  background-color: var(--dark);
  color: var(--white);
  padding: 1rem 0;
  position: sticky;
  top: 0;
  z-index: 50;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

header h1 {
  font-size: 1.5rem;
  margin-bottom: 0;
}

nav {
  display: flex;
  gap: 1rem;
  margin-top: 0.5rem;
}

nav a {
  color: var(--white);
  text-decoration: none;
  font-weight: 500;
  padding: 0.5rem;
  border-radius: 0.25rem;
  transition: all 0.2s ease;
}

nav a:hover {
  color: var(--accent);
  background-color: rgba(255, 255, 255, 0.1);
}

/* Mobile menu toggle */
.menu-toggle {
  display: none;
  background: none;
  border: none;
  color: var(--white);
  font-size: 1.5rem;
  cursor: pointer;
}

@media (max-width: 768px) {
  .menu-toggle {
    display: block;
  }

  nav {
    display: none;
    width: 100%;
    flex-direction: column;
    padding: 1rem 0;
  }

  nav.active {
    display: flex;
  }
}

/* Enhanced cards */
.card {
  background-color: var(--white);
  padding: 1.5rem;
  border-radius: 0.5rem;
  box-shadow: var(--card-shadow);
  margin-bottom: 1.5rem;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.card:hover {
  transform: translateY(-2px);
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1);
}

.card h3 {
  color: var(--secondary);
  margin-bottom: 0.75rem;
}

.card p {
  color: #4a5568;
  margin-bottom: 1rem;
}

/* Dark section */
.dark-bg {
  background-color: var(--dark);
  color: var(--white);
  padding: 2rem;
  border-radius: 0.5rem;
  margin: 2rem 0;
}

/* Links */
a {
  color: var(--primary);
  text-decoration: none;
  font-weight: 500;
  transition: color 0.2s ease;
}

a:hover {
  color: var(--accent);
  text-decoration: underline;
}

/* Footer */
footer {
  background-color: var(--dark);
  color: var(--white);
  text-align: center;
  padding: 2rem 0;
  margin-top: 3rem;
}

.footer-content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

@media (min-width: 768px) {
  .footer-content {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

/* Utility classes */
.text-center {
  text-align: center;
}
.mt-1 {
  margin-top: 0.25rem;
}
.mt-2 {
  margin-top: 0.5rem;
}
.mt-4 {
  margin-top: 1rem;
}
.mt-8 {
  margin-top: 2rem;
}
.p-4 {
  padding: 1rem;
}
.rounded-full {
  border-radius: 9999px;
}

/* Custom scrollbar */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: var(--primary);
  border-radius: 4px;
}

::-webkit-scrollbar-track {
  background-color: #edf2f7;
}

/* Animation for interactive elements */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-fade {
  animation: fadeIn 0.3s ease-out forwards;
}

/* Loading spinner */
.spinner {
  width: 2rem;
  height: 2rem;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: var(--white);
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
