/* FormDataPage.css */

.form-data-container {
  padding: 2rem;
  max-width: 1400px;
  margin: 0 auto;
  min-height: 100vh;
}

.form-data-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  flex-wrap: wrap;
  gap: 1rem;
}

.form-data-title {
  font-size: 1.75rem;
  color: #2c3e50;
  font-weight: 700;
  margin: 0;
}

.export-controls {
  display: flex;
  gap: 1rem;
}

.export-button {
  background-color: #4361ee;
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 0.375rem;
  cursor: pointer;
  font-weight: 500;
  font-size: 0.875rem;
  transition: all 0.2s ease;
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
}

.export-button:hover {
  background-color: #3a0ca3;
  transform: translateY(-1px);
}

.export-button:active {
  transform: translateY(0);
}

.export-button.csv {
  background-color: #10b981;
}

.export-button.csv:hover {
  background-color: #0d9f6e;
}

/* Loading and Error States */
.loading-state,
.error-state,
.empty-state {
  text-align: center;
  padding: 2rem;
  background-color: #f8fafc;
  border-radius: 0.5rem;
  margin-top: 1rem;
}

.error-state {
  color: #ef233c;
  background-color: #fff5f5;
}

.empty-state {
  color: #64748b;
}

/* Responsive Data Table */
.responses-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 1.5rem;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  overflow-x: auto;
  display: block;
}

@media (min-width: 768px) {
  .responses-table {
    display: table;
  }
}

.responses-table thead {
  background-color: #3a0ca3;
  color: white;
}

.responses-table th {
  padding: 1rem;
  text-align: left;
  font-weight: 600;
  text-transform: capitalize;
}

.responses-table td {
  padding: 1rem;
  border-bottom: 1px solid #e2e8f0;
  background-color: white;
  vertical-align: top;
}

.responses-table tr:hover td {
  background-color: #f8fafc;
}

/* File Download Button */
.download-button {
  background-color: #4cc9f0;
  color: white;
  padding: 0.375rem 0.75rem;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
  font-size: 0.8125rem;
  transition: all 0.2s ease;
}

.download-button:hover {
  background-color: #3aa8c9;
}

/* Delete Button */
.delete-button {
  background-color: #ef233c;
  color: white;
  padding: 0.375rem 0.75rem;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
  font-size: 0.8125rem;
  transition: all 0.2s ease;
}

.delete-button:hover {
  background-color: #d90429;
}

/* Timestamp Cell */
.timestamp-cell {
  white-space: nowrap;
  color: #64748b;
  font-size: 0.875rem;
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
  .form-data-container {
    padding: 1rem;
  }

  .form-data-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .export-controls {
    width: 100%;
    flex-wrap: wrap;
  }

  .export-button {
    flex-grow: 1;
    justify-content: center;
  }

  .responses-table {
    display: block;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .responses-table th,
  .responses-table td {
    padding: 0.75rem 0.5rem;
    font-size: 0.8125rem;
  }
}

/* Scrollable table container for mobile */
.table-container {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  margin-top: 1rem;
  border-radius: 0.5rem;
}

/* Loading spinner */
.spinner {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: white;
  animation: spin 1s ease-in-out infinite;
  margin-right: 0.5rem;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Empty table state */
.empty-table {
  text-align: center;
  padding: 2rem;
  background-color: #f8fafc;
  border-radius: 0.5rem;
  color: #64748b;
}
