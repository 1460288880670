.admin-container {
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  min-height: 100vh;
}

.admin-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #e2e8f0;
}

.admin-title {
  font-size: 1.75rem;
  color: #2c3e50;
  font-weight: 700;
  margin: 0;
}

.admin-actions {
  display: flex;
  gap: 1rem;
}

.admin-button {
  background-color: #4361ee;
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 0.375rem;
  cursor: pointer;
  font-weight: 500;
  font-size: 0.875rem;
  transition: all 0.2s ease;
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
}

.admin-button:hover {
  background-color: #3a0ca3;
  transform: translateY(-1px);
}

.admin-button:active {
  transform: translateY(0);
}

.admin-button.logout {
  background-color: #ef233c;
}

.admin-button.logout:hover {
  background-color: #d90429;
}

.admin-button.primary {
  background-color: #4361ee;
}

.admin-button.secondary {
  background-color: #3a0ca3;
}

.admin-button.accent {
  background-color: #f72585;
}

/* Forms List */
.forms-list {
  margin-top: 2rem;
}

.forms-empty {
  text-align: center;
  color: #64748b;
  padding: 2rem;
  background-color: #f8fafc;
  border-radius: 0.5rem;
}

.form-item {
  background-color: white;
  padding: 1.5rem;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  margin-bottom: 1rem;
  transition: all 0.2s ease;
}

.form-item:hover {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  transform: translateY(-2px);
}

.form-title {
  font-size: 1.25rem;
  color: #2c3e50;
  margin-bottom: 1rem;
  font-weight: 600;
}

.form-actions {
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
  margin-top: 1rem;
}

/* Responsive Design */
@media (max-width: 768px) {
  .admin-container {
    padding: 1rem;
  }

  .admin-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }

  .admin-actions {
    width: 100%;
    flex-direction: column;
  }

  .admin-button {
    width: 100%;
    justify-content: center;
    padding: 0.75rem;
  }

  .form-actions {
    flex-direction: column;
  }

  .form-actions button {
    width: 100%;
  }
}

/* Button variants for different actions */
.button-view {
  background-color: #4361ee;
}

.button-copy {
  background-color: #3a0ca3;
}

.button-responses {
  background-color: #4cc9f0;
}

.button-edit {
  background-color: #f8961e;
}

.button-delete {
  background-color: #ef233c;
}

/* Hover states for specific buttons */
.button-view:hover {
  background-color: #3a56c9;
}

.button-copy:hover {
  background-color: #2e0b85;
}

.button-responses:hover {
  background-color: #3aa8c9;
}

.button-edit:hover {
  background-color: #e07e0c;
}

.button-delete:hover {
  background-color: #d90429;
}

/* Loading state */
.loading-spinner {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: white;
  animation: spin 1s ease-in-out infinite;
  margin-right: 0.5rem;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Confirmation dialog styles */
.confirm-dialog {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.confirm-content {
  background-color: white;
  padding: 2rem;
  border-radius: 0.5rem;
  max-width: 400px;
  width: 100%;
  box-shadow: 0 10px 25px -5px rgba(0, 0, 0, 0.1);
}

.confirm-buttons {
  display: flex;
  gap: 1rem;
  margin-top: 1.5rem;
  justify-content: flex-end;
}

/* Toast notification */
.toast {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  background-color: #10b981;
  color: white;
  padding: 0.75rem 1.5rem;
  border-radius: 0.375rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  animation: slideIn 0.3s ease-out;
}

@keyframes slideIn {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
